.quill em {
  font-style: italic;
}

.quill strong {
  font-weight: bold;
}

.mention {
  padding: 0;
  background-color: transparent;
  font-weight: 700;
}

.mention span {
  margin: 0;
}

.mention:hover {
  cursor: pointer;
}

.ql-mention-list-container {
  border-radius: 6px;
  box-shadow: 0 0 16px 0 #00000044;
  width: 200px;
}
.ql-mention-list-item {
  padding: 6px 8px;
  line-height: inherit;
}
.ql-mention-list-item:first-of-type {
  border-radius: 6px 6px 0 0;
}
.ql-mention-list-item:last-of-type {
  border-radius: 0 0 6px 6px;
}
